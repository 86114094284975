import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  alpha,
  Autocomplete,
  autocompleteClasses,
  InputAdornment,
  InputBase,
  Popper,
  styled,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { format } from 'libphonenumber-js';
import React, { useCallback, useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { differenceInYears } from 'date-fns';
import { debounce } from 'lodash';
import { PatientAutocompleteOption } from './NavBar.types';
import PatientSearchResult from './PatientSearchResult';

const dobFormatter = new Intl.DateTimeFormat('en-US');

const classes = {
  searchInput: 'searchInput',
  adornment: 'adornment',
};

type InputProps = {
  active: boolean;
};

const StyledInput = styled('div', { shouldForwardProp: (prop) => prop !== 'active' })<InputProps>(
  ({ theme, active }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    [`& .${classes.adornment}`]: {
      padding: theme.spacing(0, 1),
      position: 'absolute',
      fontSize: '14px',
      color: active ? theme.palette.text.secondary : theme.palette.common.white,
    },

    [`& .${classes.searchInput}`]: {
      color: theme.palette.common.white,
      fontSize: '14px',
      padding: theme.spacing(0.5, 0.5, 0.5, 4),
      transition: theme.transitions.create(['width', 'background-color', 'color', 'border']),
      borderRadius: theme.shape.borderRadius,
      width: '200px',
      '&:focus': {
        width: '360px',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        borderRadius: theme.shape.borderRadius,
      },
    },
  }),
);

const onelifeUrl = process.env.REACT_APP_ONELIFE_URL;
const chartUrl = process.env.REACT_APP_CLINICAL_UX_URL;
const memberManagementUrl = process.env.REACT_APP_MEMBER_MANAGEMENT_ASSETS_URL;
const maxAutocompleteResults = 10;

export const patientAutocompleteQuery = gql`
  query PatientAutocomplete($textQuery: String!) {
    patientSearch(textQuery: $textQuery) {
      id
      label
      emails
      phones
      dob
    }
  }
`;

export type PatientAutocompleteResult = {
  id: string;
  label: string;
  phones: string[];
  emails: string[];
  dob: string;
};

const StyledPopper = styled(Popper)(() => ({
  [`& .onelife-container-${autocompleteClasses.paper}`]: {
    width: '400px',
    right: 0,
    position: 'absolute',
  },
  [`& .onelife-container-${autocompleteClasses.listbox}`]: {
    maxHeight: 'none',
    overflow: 'hidden',
  },
}));

function PatientSearchBox({ isAdmin }: { isAdmin: boolean }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [active, setActive] = useState(false);
  const { memberManagementUi } = useFlags();
  const allResultsLink = `${onelifeUrl}/admin/search?utf8=✓&name=${searchQuery}&service_area_id=all`;

  const [search, { data: searchResults, error }] = useLazyQuery<
    { patientSearch: PatientAutocompleteResult[] },
    { textQuery: string }
  >(patientAutocompleteQuery);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(search, 250), [search]);

  useEffect(() => {
    if (searchQuery) debouncedSearch({ variables: { textQuery: searchQuery } });
  }, [debouncedSearch, searchQuery]);

  useEffect(() => {
    if (error) {
      error.message = `Patient search failed: ${error.message}`;
      Sentry.captureException(error);
    }
  }, [error]);

  const mapElasticSearchResultsToOptions = () => {
    if (!searchResults?.patientSearch) {
      return [];
    }

    const patientResults: PatientAutocompleteOption[] = searchResults.patientSearch.map(
      (result) => {
        const { dob, emails, id, label, phones } = result;
        const formattedAge = `${differenceInYears(new Date(), new Date(dob))} yo`;
        const contactInfo = phones.includes(searchQuery.replace(/-|\(|\)|\s/g, ''))
          ? format(phones[0], 'US', 'National')
          : emails && emails.join(', ');

        let link = `${chartUrl}/#/patients/${id}/chart/summaries/new`;
        if (isAdmin && memberManagementUi) {
          link = `${memberManagementUrl}/account/${id}/`;
        } else if (isAdmin) {
          link = `${onelifeUrl}/${id}/account`;
        }

        const displayDob = dob ? dobFormatter.format(new Date(`${dob}T00:00:00.000`)) : '';

        return {
          key: id,
          id: Number(id),
          display_dob: displayDob,
          title: label,
          description: `${displayDob} (${formattedAge}) · ${contactInfo}`,
          link,
        };
      },
    );

    return searchResults.patientSearch.length >= maxAutocompleteResults
      ? patientResults.concat([
          {
            key: 'view-all',
            title: 'View All Results',
            link: allResultsLink,
          },
        ])
      : patientResults;
  };

  const options = mapElasticSearchResultsToOptions();

  const onChange = (
    _: React.ChangeEvent<unknown>,
    data: PatientAutocompleteOption | string | null,
  ) => {
    // freesolo prop on Autocomplete causes this to sometimes be a string
    if (!data || typeof data === 'string') return;
    window.open(data.link, '_blank', 'noopener noreferrer');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && searchQuery) {
      window.open(allResultsLink, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <Autocomplete
      autoComplete
      fullWidth
      freeSolo
      open={active}
      blurOnSelect
      PopperComponent={StyledPopper}
      onKeyPress={handleKeyPress}
      options={options}
      getOptionLabel={() => searchQuery}
      onChange={onChange}
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
      onInputChange={(_, value) => setSearchQuery(value)}
      inputValue={searchQuery}
      data-cy="patient-search"
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.key}>
          <PatientSearchResult option={option} searchQuery={searchQuery} />
        </li>
      )}
      renderInput={({ InputProps: { ref }, inputProps }) => (
        <StyledInput active={active}>
          <InputBase
            startAdornment={
              <InputAdornment className={classes.adornment} position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            }
            ref={ref}
            placeholder={active ? 'Search by name, DOB, phone, or email' : 'Patient search'}
            classes={{ input: classes.searchInput }}
            inputProps={{ ...inputProps, autoComplete: 'new-password' }}
            data-cy="patient-search-input"
          />
        </StyledInput>
      )}
    />
  );
}

export default PatientSearchBox;
