import NavMenu from './NavMenu';

interface NavMenuWrapperProps {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null | undefined;
  onClose: () => void;
  children: React.ReactNode;
}

function NavMenuWrapper({ id, open, anchorEl, onClose, children }: NavMenuWrapperProps) {
  return (
    <NavMenu
      id={id}
      anchorEl={anchorEl}
      disableScrollLock
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {children}
    </NavMenu>
  );
}

export default NavMenuWrapper;
