import { MenuItem } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NavLink } from 'react-router-dom';
import { RoleName, useProfile } from './hooks/useProfile';
import { InternalUser } from './models/InternalUser';
import { getTodaysDate, ONELIFE_URL } from './utils/constants';

interface AppointmentsMenuItemsProps {
  profile: InternalUser;
  handleClose: () => void;
}

type AppointmentsMenuItemConfig = {
  label: string;
  to?: string;
  href?: string;
  dataCy: string;
  requiresRole?: RoleName;
  hideIfFlag?: string;
};

const menuItems: AppointmentsMenuItemConfig[] = [
  {
    label: 'Appointments',
    to: '/schedule/appointments',
    dataCy: 'appointments-nav',
  },
  {
    label: 'Appointment Scanner',
    to: '/schedule/appointment-scanner',
    dataCy: 'appointment-scanner-nav',
  },
  {
    label: 'Appointment Cancellations',
    to: '/schedule/appointment-cancellation-requests',
    dataCy: 'appointment-cancellation-requests-nav',
    requiresRole: 'MASS_CANCELLATION',
  },
  {
    label: 'Provider Schedule',
    to: '/schedule/provider-schedule',
    dataCy: 'provider-schedule-nav',
    hideIfFlag: 'omniMsa',
  },
  {
    label: 'Office Schedule',
    href: `${ONELIFE_URL}/admin/offices/{{officeId}}/schedules?date={{todaysDate}}`,
    dataCy: 'office-schedule-nav',
    hideIfFlag: 'omniMsa',
  },
  {
    label: 'Schedule Templates',
    to: '/schedule/templates',
    dataCy: 'schedule-templates-nav',
    requiresRole: 'CALENDAR_ADMIN',
    hideIfFlag: 'omniMsa',
  },
  {
    label: 'Phlebotomy Visits',
    to: '/schedule/phlebotomy-visits',
    dataCy: 'phlebotomy-visits-nav',
  },
  {
    label: 'Unregistered Patients',
    href: `${ONELIFE_URL}/admin/appointments/unregistered`,
    dataCy: 'unregistered-patients-nav',
  },
  {
    label: 'Virtual Visits',
    href: `${ONELIFE_URL}/admin/video_appointments`,
    dataCy: 'virtual-visits-nav',
  },
];

function AppointmentsMenuItems({ profile, handleClose }: AppointmentsMenuItemsProps) {
  const { hasRole } = useProfile();
  const flags = useFlags();
  const todaysDate = getTodaysDate();

  return (
    <>
      {menuItems.map(({ label, to, href, dataCy, requiresRole, hideIfFlag }) => {
        if (requiresRole && !hasRole(requiresRole)) return null;
        if (hideIfFlag && flags[hideIfFlag]) return null;

        const finalHref = href
          ?.replace('{{officeId}}', profile?.homeOffice?.id || '')
          .replace('{{todaysDate}}', todaysDate);

        return (
          <MenuItem
            key={dataCy}
            component={to ? NavLink : 'a'}
            to={to}
            href={finalHref}
            data-cy={dataCy}
            onClick={handleClose}
          >
            {label}
          </MenuItem>
        );
      })}
    </>
  );
}

export default AppointmentsMenuItems;
