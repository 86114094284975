import { Chip, MenuItem, Theme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { InternalUser } from './models/InternalUser';
import { getTodaysDate, ONELIFE_URL } from './utils/constants';
import { RoleName, useProfile } from './hooks/useProfile';

interface SchedulingMenuItemsProps {
  profile: InternalUser;
  handleClose: () => void;
}

type SchedulingMenuItemConfig = {
  label: string;
  to?: string;
  href?: string;
  dataCy: string;
  showNewChip?: boolean;
  requiresRole?: RoleName;
};

const newChipStyles = (theme: Theme) => ({
  ml: 1,
  borderRadius: '4px',
  lineHeight: '150%',
  letterSpacing: '0.16px',
  display: 'flex',
  alignItems: 'center',
  padding: '3px 4px',
  '&&& span': {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
    padding: '0 6px',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&.MuiChip-outlined:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
});

const menuItems: SchedulingMenuItemConfig[] = [
  {
    label: 'Provider Schedule',
    to: '/schedule/omni-msa',
    dataCy: 'provider-schedule-nav',
    showNewChip: true,
  },
  {
    label: 'Office Schedule',
    href: `${ONELIFE_URL}/admin/offices/{{officeId}}/schedules?date={{todaysDate}}`,
    dataCy: 'office-schedule-nav',
  },
  {
    label: 'Office Management',
    to: '/schedule/office-management',
    dataCy: 'office-management-nav',
  },
  {
    label: 'Holidays',
    to: '/schedule/holidays',
    dataCy: 'holidays-nav',
  },
  {
    label: 'Schedule Templates',
    to: '/schedule/templates',
    dataCy: 'schedule-templates-nav',
    requiresRole: 'CALENDAR_ADMIN',
  },
];

function SchedulingMenuItems({ profile, handleClose }: SchedulingMenuItemsProps) {
  const { hasRole } = useProfile();
  const todaysDate = getTodaysDate();

  return (
    <>
      {menuItems.map(({ label, to, href, dataCy, showNewChip, requiresRole }) => {
        if (requiresRole && !hasRole(requiresRole)) return null;

        const finalHref = href
          ?.replace('{{officeId}}', profile?.homeOffice?.id || '')
          .replace('{{todaysDate}}', todaysDate);

        return (
          <MenuItem
            key={dataCy}
            component={to ? NavLink : 'a'}
            to={to}
            href={finalHref}
            data-cy={dataCy}
            onClick={handleClose}
          >
            {label}
            {showNewChip && (
              <Chip
                label="NEW"
                variant="outlined"
                color="primary"
                size="small"
                sx={newChipStyles}
              />
            )}
          </MenuItem>
        );
      })}
    </>
  );
}

export default SchedulingMenuItems;
