interface HostedApp {
  /**
   * A unique identifier for this micro-frontend
   */
  id: string;
  /**
   * The path for the microfrontend
   */
  path: string;
  /**
   * The URL where the micro-frontend app is hosted
   */
  host: string;
  /**
   * The name of the repository for the micro-frontend
   */
  repo: string;
}

/**
 * List of microfrontends and their app information
 */
const hostedApps: HostedApp[] = [
  {
    id: 'admin',
    path: '/admin',
    host: process.env.REACT_APP_ADMIN_ASSETS_URL!,
    repo: 'onelife-admin',
  },
  {
    id: 'schedule',
    path: '/schedule',
    host: process.env.REACT_APP_SCHEDULE_ASSETS_URL!,
    repo: 'onelife-schedule',
  },
  {
    id: 'panel-management',
    path: '/panel-management',
    host: process.env.REACT_APP_PANEL_MANAGEMENT_ASSETS_URL!,
    repo: 'panel-management-dashboard',
  },
  {
    id: 'reconciliations',
    path: '/reconciliations',
    host: process.env.REACT_APP_RECONCILIATIONS_ASSETS_URL!,
    repo: 'reconciliations',
  },
  {
    id: 'external_records',
    path: '/external_records',
    host: process.env.REACT_APP_RECONCILIATIONS_ASSETS_URL!,
    repo: 'reconciliations',
  },
  {
    id: 'members',
    path: '/members',
    host: process.env.REACT_APP_MEMBER_MANAGEMENT_ASSETS_URL!,
    repo: 'member-management-ui',
  },
  {
    id: 'scribe',
    path: '/scribe',
    host: process.env.REACT_APP_SCRIBE_ASSETS_URL!,
    repo: 'scrbie',
  },
];

export default hostedApps;
