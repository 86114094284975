import { useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export interface NavigationEvent {
  origin: string;
  pathname: string;
  search: string;
  hash: string;
  timestamp: number;
}

/**
 * Hook to log url change events and alert Sentry to url flickering events
 * @param threshold The number of frequent flickering events needed to alert.
 * @param timeWindow In milliseconds. The window in which to log successive url changes and detect flickering.
 */
const useUrlFlickerDetection = (threshold = 5, timeWindow = 5000) => {
  const location = useLocation();
  const NAVIGATION_STORAGE_KEY = 'navigationEvents';
  const navigationStorageRef = useRef<NavigationEvent[]>([]);
  const lastLocationRef = useRef(location);
  const hasAlertedRef = useRef(false);

  /**
   * load NavigationEvents from localstorage and filter out stale ones
   * @returns array of NavigationEvents from localstorage
   */
  const loadNavigationEvents = useCallback((): NavigationEvent[] => {
    const localNavigationEvents = localStorage.getItem(NAVIGATION_STORAGE_KEY);
    if (localNavigationEvents) {
      // parse stored events and filter out expired ones
      const events: NavigationEvent[] = JSON.parse(localNavigationEvents);
      const now = Date.now();
      return events.filter((event) => now - event.timestamp <= timeWindow);
    }
    return [];
  }, [timeWindow]);

  useEffect(() => {
    navigationStorageRef.current = loadNavigationEvents();
  }, [loadNavigationEvents]);

  useEffect(() => {
    if (
      location.pathname === lastLocationRef.current.pathname &&
      location.search === lastLocationRef.current.search &&
      location.hash === lastLocationRef.current.hash
    ) {
      return; // location hasn't changed, don't do anything
    }

    // log all location change events and alert if threshold exceeded
    const now = Date.now();

    const newNavigationEvent: NavigationEvent = {
      origin: window.location.origin,
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
      timestamp: now,
    };

    // save the new navigation event
    navigationStorageRef.current = [...navigationStorageRef.current, newNavigationEvent];

    // remove events older than current time window
    navigationStorageRef.current = navigationStorageRef.current.filter(
      (event) => now - event.timestamp <= timeWindow,
    );

    // if the number of navigationEvents within the time window has exceeded the threshold, send a url flickering alert
    if (navigationStorageRef.current.length > threshold && !hasAlertedRef.current) {
      // save the full url and turn the timestamp into something human-readable
      const flickeringData = navigationStorageRef.current.map((event) => ({
        url: `${event.origin}${event.pathname}${event.search}${event.hash}`,
        time: new Date(event.timestamp).toISOString(),
      }));

      Sentry.captureEvent({
        message: 'URL Flickering Detected',
        level: 'error',
        extra: {
          events: flickeringData,
        },
      });
      hasAlertedRef.current = true;
    } else if (navigationStorageRef.current.length <= threshold) {
      // flickering seems to have stopped
      hasAlertedRef.current = false;
    }

    localStorage.setItem(NAVIGATION_STORAGE_KEY, JSON.stringify(navigationStorageRef.current));
    lastLocationRef.current = location;
  }, [location, threshold, timeWindow]);

  return { navigationEvents: navigationStorageRef };
};

export default useUrlFlickerDetection;
